import React, {useEffect, useState} from 'react';
import './styles/new.scss';
import bikLogo from './img/BIK.svg';
import fondLogo from './img/Fond.svg';
import axios from 'axios';
import {Galleria} from 'primereact/galleria';
import ApiConfig from "./api/apiConfig";

import mapMarker from './img/map_marker.svg';

function App() {
    const [microdistricts, setMicrodistricts] = useState([]);
    const [microdistrictInfo, setMicrodistrictInfo] = useState(null);
    const [microdistrictIndex, setMicrodistrictIndex] = useState(0);
    const [banners, setBanners] = useState([]);
    const [currentTime, setCurrentTime] = useState(0);

    const microdistrictsSwapTime = 1000 * 6
    const bannerSwapTime = 1000 * 3
    const requestTimer = 1000 * 60 * 10;

    useEffect(() => {
        updateClock();
        fetchMicrodistricts().then(r => setMicrodistricts(r));
        fetchBanners().then(b => setBanners(b));
    }, []);

    useEffect(() => {
        const timer = setInterval(updateClock, (60 - currentTime.seconds) * 1000)
        return () => clearInterval(timer)
    }, [currentTime]);

    useEffect(() => {
        const timer = setInterval(() => {
            console.log('Новый запрос на банеры и участки')
            fetchMicrodistricts().then(r => setMicrodistricts(r));
            fetchBanners().then(b => setBanners(b));
            console.log('banners', banners)
        }, requestTimer);
        return () => clearInterval(timer)
    }, [microdistricts, banners]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (microdistricts.length > 0) {
                swapMicrodistricts();
            }
        }, microdistrictsSwapTime);

        return () => clearInterval(intervalId);
    }, [microdistricts, microdistrictIndex]);

    const swapMicrodistricts = () => {
        try {
            const microdistrictsWithPlots = microdistricts.filter(microdistrict => microdistrict.land_plots_count > 0);

            if (microdistrictsWithPlots.length > 0) {
                const nextIndex = (microdistrictIndex + 1) % microdistrictsWithPlots.length;
                setMicrodistrictIndex(nextIndex);
                setMicrodistrictInfo(microdistrictsWithPlots[nextIndex]);
            }
        } catch (e) {
            console.log('error', e);
            setMicrodistrictInfo(null);
        }
    }

    const updateClock = () => {
        const date = new Date();
        setCurrentTime({
            day: date.getDate(),
            month: date.toLocaleString('default', {month: 'long'}),
            year: date.getFullYear(),
            hour: date.getHours() >= 10 ? `${date.getHours()}` : `0${date.getHours()}`,
            minutes: date.getMinutes() >= 10 ? `${date.getMinutes()}` : `0${date.getMinutes()}`,
            seconds: date.getSeconds(),
        })
    };

    const fetchMicrodistricts = async () => {
        try {
            console.log('ApiConfig.baseUrl', ApiConfig.baseUrl)
            const response = await axios.get(ApiConfig.baseUrl + 'geo/microdistrict/tv-list/');
            console.log('участки', response.data)
            return response.data; // Вернуть данные из ответа Axios
        } catch (error) {
            console.error('Ошибка:', error);
            return [];
        }
    };

    const fetchBanners = async () => {
        try {
            const response = await axios.get(ApiConfig.baseUrl + 'geo/microdistrict/get_banners/');
            return response.data;
        } catch (error) {
            console.error('Ошибка:', error);
            return []
        }
    };


    const pluralize = (count) => {
        if (count % 10 === 1 && count % 100 !== 11) {
            return 'участок';
        } else if ([2, 3, 4].includes(count % 10) && ![12, 13, 14].includes(count % 100)) {
            return 'участка';
        } else {
            return 'участков';
        }
    };

    const Clock = ({curTime}) => {
        return <div className={"clock"}>
            <div className={"date"}>
                <div className={"day"}>{curTime.day}</div>
                <div className={"month"}>{curTime.month}</div>
                <div className={"year"}>{curTime.year}</div>
            </div>
            <div className={"time"}>
                {curTime.hour}:{curTime.minutes}
            </div>
        </div>
    }

    const anyBanner = () => {
        return (
            <div className={"any"}>
                <img className={"bikLogo"} src={bikLogo}/>
                <img className={"fondLogo"} src={fondLogo}/>
            </div>
        )
    }

    return (
        <div>
            <div className={"screen"}>
                <div className={"container"}>
                    <div className={"header"}>
                        <div className={"title"}>
                            <div className={"title-text"}>Земельные участки в продаже</div>
                            <Clock curTime={currentTime}/>
                        </div>
                        <div className={"place"}>
                            <div className={"name"}>{microdistrictInfo?.name}</div>
                            <div className={"municipality"}><img src={mapMarker}
                                                                 alt="Not Founded"/> {microdistrictInfo?.municipality}
                            </div>
                        </div>
                    </div>
                    <div className={"info"}>
                        <div className={"plots-count"}>
                            {microdistrictInfo?.land_plots_count} {pluralize(microdistrictInfo?.land_plots_count)}
                            <br/>
                            <div className={"addition-text"}>в продаже</div>
                        </div>

                        <div className={"prices"}>
                            {microdistrictInfo?.min_land_plot_cost && microdistrictInfo?.max_land_plot_cost ?
                                <div>
                                    {microdistrictInfo?.min_land_plot_cost !== microdistrictInfo?.max_land_plot_cost &&
                                        (<div>от {microdistrictInfo?.min_land_plot_cost.toLocaleString()} ₽</div>)}
                                    <div>до {microdistrictInfo?.max_land_plot_cost.toLocaleString()} ₽</div>
                                </div> :
                                <div>уточните стоимость у менеджера</div>
                            }
                        </div>
                    </div>
                    <div className={"plots"}>
                        <div className={"block"}>
                            <text className={"main-text"}>
                                Участки под ИЖС <text
                                className={"num"}>{microdistrictInfo?.residential_plots_count}</text>
                            </text>
                        </div>
                        <div className={"block"}>
                            <text className={"main-text"}>
                                Коммерческие участки <text
                                className={"num"}>{microdistrictInfo?.commercial_plots_count}</text>
                            </text>
                        </div>
                    </div>
                </div>
                <div className={"banner"}>
                    {banners.length > 0 ?
                        <Galleria
                            value={banners}
                            showThumbnails={false}
                            showThumbnailNavigators={false}
                            showIndicators={false}
                            autoPlay
                            circular
                            transitionInterval={bannerSwapTime}
                            unstyled={true}
                            item={(item) => <img src={item} alt={anyBanner()} onError={() => {
                                anyBanner()
                            }}/>}
                        /> :
                        anyBanner()
                    }
                </div>
            </div>
        </div>
    );
}

export default App;
